<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>로케이션관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2 >기본정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명</label>
            <ul>
              <li>{{locaInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd ><label>회사코드</label>
            <ul>
              <li>{{ locaInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>로케이션명</label>
            <ul>
              <li>{{locaInfo.locaNm}}</li>
            </ul>
          </dd>
          <dd ><label>로케이션코드</label>
            <ul>
              <li>{{ locaInfo.locaCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>주소</label>
            <ul>
              <li>{{locaInfo.locaAddr }}</li>
            </ul>
          </dd>
          <dd><label>상세주소</label>
            <ul>
              <li>{{locaInfo.locaAddrDtl}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>재고 출고지 설정</label>
            <ul>
              <li>{{locaInfo.inoutDsgnGbnNm}}{{ !!(locaInfo.inoutDsgnGbnCdDesc) ? `(${locaInfo.inoutDsgnGbnCdDesc})` : ``}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>사용여부</label>
            <ul>
              <li>{{locaInfo.activeYn === 'Y' ? '활성화' : '비활성화'}}</li>
            </ul>
          </dd>
          <dd><label>비고</label>
            <ul>
              <li>{{locaInfo.contents}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>등록</label>
            <ul>
              <li>{{locaInfo.frstRgstNm }} | {{locaInfo.frstRgstDt }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{locaInfo.lastMdfyNm}} | {{locaInfo.lastMdfyDt }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>관리자정보 <span class="forg">* 로케이션 관리자는 대표 1인만 지정 가능합니다.</span></h2>
        <ul class="filter_list">
          <li>
            <dd><label>성명</label>
              <ul><li>{{locaInfo.mngUnqUserNm}}</li></ul>
            </dd>
            <dd><label>ID</label>
              <ul><li>{{locaInfo.mngUserId}}</li></ul>
            </dd>
          </li>
          <li>
            <dd><label>휴대전화번호</label>
              <ul><li>{{locaInfo.spTelNo}}</li></ul>
            </dd>
            <dd><label>메일주소</label>
              <ul><li>{{locaInfo.email}}</li></ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>프린터 IP</h2>
        <ul class="filter_list">
          <li>
            <dd><label>IP</label>
              <ul><li>
                {{ locaInfo.printerIp }}
              </li></ul>
            </dd>
            <dd><label>포트</label>
              <ul><li>
                {{ locaInfo.printerPort }}
              </li></ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>섹터정보</h2></div>
      <kendo-grid
          class="mt20"
          style="cursor: pointer"
          :data-source="sectListInfo"
          :navigatable="false"
          :sortable-mode="'multiple'"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="'row'"
          :columns="gridColumns"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
      </kendo-grid>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'LocationManageList' })">목록</button>
        </li>
        <li class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteLocation">삭제</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'LocationManageEdit' })">수정</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name   : 'LocationManageDetail',
  created () {
    const vm = this
    vm.getLocationManageDetail()
  },
  methods : {
    getLocationManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/loca/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.locaInfo = response.data.resultData
            vm.locaInfo.inoutDsgnGbnCdDesc = vm.$store.state.codeRequestStore.search.combobox.inoutDsgnGbn.data.find(item=>item.cdId === vm.locaInfo.inoutDsgnGbn )?.cdDesc1
            vm.sectListInfo = EtnersCommonUtil.setRowNumber(response.data.resultData.locaWhSectResponseList)
          } else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    onClickDeleteLocation : function() {
      const vm = this
      kendo.confirm('로케이션를 삭제하시겠습니까?').done(function (){
        vm.deleteLocation()
      })
    },
    deleteLocation : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/loca/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'LocationManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      const vm = this
      const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
      const centerAttributes = { style: 'text-align: center;' }
      return {
        ApiConfig : { fmsDomain : ApiConfig.fmsDomain },
        locaInfo  : {},
        sectListInfo : [],
        gridColumns : [
          {
            field: 'rowNumber',
            title: 'No',
            width: '3%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'whNm',
            title: '구역명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'sectCd',
            title: '섹터코드',
            width: '8%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'sectNm',
            title: '섹터명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'sectLocation',
            title: '위치',
            width: '20%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'goodsExistYn',
            title: '상품입고여부',
            width: '8%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'area',
            title: '면적(평)',
            width: '8%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
      }
    },
  }
</script>
